import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import VideoBg from "../components/videoBg";

import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import 'swiper/css';
import PageHeader from "../components/pageHeader";
import PageSubHeader   from "../components/pageSubHeader";
import CaseTestimonials from "../components/caseTestimonials";
import CaseSwitcher from "../components/caseSwitcher";
import CaseHeroImage from "../components/caseHeroImage";
import EmotiveImage from "../img/cases/emotive/preview.png";
import EmotiveImage0 from "../img/cases/emotive/image_0.png";
import CaseImagesBlock from "../components/caseImagesBlock";
import EmotiveImage21 from "../img/cases/emotive/image_2_1.png";
import EmotiveImage22 from "../img/cases/emotive/image_2_2.png";
import EmotiveImage23 from "../img/cases/emotive/image_2_3.png";
import EmotiveImage24 from "../img/cases/emotive/image_2_4.png";
import EmotiveImage31 from "../img/cases/emotive/image_3_1.png";
import EmotiveImage32 from "../img/cases/emotive/image_3_2.png";
import CaseImageSlide from "../components/caseImageSlide";
import EmotiveImage4 from "../img/cases/emotive/image_4.png";
const EmotiveCase = () => {

    return (
        <>
            <Helmet>
                <title>The Emotive Clouds Show | Works | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                      rel="stylesheet"/>
            </Helmet>

            <VideoBg/>

            <div className="site-section__about-top-background"></div>

            <section id="main-section" className="site-section">
                <PageHeader
                    annotationText={"@ Cloudera EVOLVE Conference 2024"}
                    titleText={"The Emotive Clouds Show"}
                    subTitleText={"A dynamic cloud show that can craft new visuals using physical stimulation or heartbeat frequency at Cloudera EVOLVE Conference Singapore 2024"}
                />
                <CaseTestimonials testimonials={['Artificial intelligence', 'Art installation',]}/>
                <CaseHeroImage image={EmotiveImage0} imageALT="The Emotive Clouds Show"/>
                <PageSubHeader text="When we look at the clouds we see different things, we see possibilities for them to be something more. The objective was for participants to interact with the digital clouds, shaping and moulding them to create unique, personalised NFTs that reflect their engagement with Cloudera’s technology."/>
                <CaseImagesBlock images={[EmotiveImage21, EmotiveImage22, EmotiveImage23, EmotiveImage24]}/>
                <PageSubHeader text="The interactive display shows a dynamic cloud visual which reacts to the user's heartbeat data which then influences the creation of dynamic cloud formations, reflecting the participant’s emotional state."/>
                <CaseImagesBlock images={[EmotiveImage31, EmotiveImage32]}/>
                <PageSubHeader text="The installations offered 9 types of cloud visuals in total, with each design representing the local culture, landmarks, and themes of where the EVOLVE Conference took place, making it more relevant to the location and its nuances."/>
                <CaseImageSlide image={EmotiveImage4} title="Business objective:" text="The Evolve Conference by Cloudera focuses on exploring how data and AI can revolutionise businesses. The installation served as an engaging marketing campaign culminating in the creation of unique NFTs for the visitors." imagePosition="right"/>
                <CaseSwitcher previousLink="/works/mothers-day-queen/" previousName="Mother’s Day Queen" nextLink="/works/digital-muse/" nextName="Digital Muse"/>
            </section>

        </>
    )
}

export default EmotiveCase;
